define("discourse/plugins/discourse-ai/discourse/lib/ai-streamer/updaters/summary-updater", ["exports", "discourse/lib/text", "discourse/plugins/discourse-ai/discourse/lib/ai-streamer/updaters/stream-updater"], function (_exports, _text, _streamUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SummaryUpdater extends _streamUpdater.default {
    constructor(topicSummary, componentContext) {
      super();
      this.topicSummary = topicSummary;
      this.componentContext = componentContext;
      if (this.topicSummary) {
        this.summaryBox = document.querySelector("article.ai-summary-box");
      }
    }
    get element() {
      return this.summaryBox;
    }
    set streaming(value) {
      if (this.element) {
        if (value) {
          this.componentContext.isStreaming = true;
        } else {
          this.componentContext.isStreaming = false;
        }
      }
    }
    async setRaw(value, done) {
      this.componentContext.oldRaw = value;
      const cooked = await (0, _text.cook)(value);
      await this.setCooked(cooked);
      if (done) {
        this.componentContext.finalSummary = cooked;
      }
    }
    async setCooked(value) {
      this.componentContext.text = value;
    }
    get raw() {
      return this.componentContext.oldRaw || "";
    }
  }
  _exports.default = SummaryUpdater;
});